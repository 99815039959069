/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { Account, ServiceType } from '@newfront-insurance/account-api';
import { NotFoundError } from '@newfront-insurance/data-layer-client';
import { useQuery as useRouteQuery } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';
import invariant from 'tiny-invariant';

import { TRPCProvider } from './trpc';

export const AccountDataProvider = createProvider(
  (): Account & {
    defaultServiceType: ServiceType;
  } => {
    const accountUuid = useRouteQuery<string>('accountUuid');
    const { useQuery } = useProvider(TRPCProvider);

    const { data } = useQuery(['account.getAccount', { accountUuid }], {
      suspense: true,
    });
    // Data is always loaded with suspesne
    invariant(data, 'Data not loaded');

    const { accountResult } = data;

    // All accounts should have a service type
    if (accountResult.serviceTypes.length === 0) {
      throw new NotFoundError('No service types found for account');
    }

    return {
      ...accountResult,
      defaultServiceType: accountResult.serviceTypes[0],
    };
  },
);
