import type { AccountProgramType } from '@newfront-insurance/files-api';
import type { Route } from '@newfront-insurance/next-router-provider';
import { isUndefined, omitBy } from 'lodash';

import { getConfig } from '@/config';

export interface AccountRoute extends Record<string, string | undefined> {
  accountUuid: string;
}

interface PolicyRoute extends AccountRoute {
  policyUuid: string;
}

interface CertificateRoute extends AccountRoute {
  certificateId: string;
}

interface ClaimRoute extends AccountRoute {
  claimUuid: string;
}

interface TaskRoute extends AccountRoute {
  taskUuid: string;
}

export interface InsurableItemsQuery extends Record<string, string | string[] | undefined> {
  accountUuid: string;
  type?: string;
  insurableItemUuid?: string;
}

export function appRoute<Query extends Record<string, string | string[] | undefined>>(pathname: string) {
  return (query?: Query): Route => {
    return {
      pathname,
      query: omitBy(query, (value) => isUndefined(value) || value === '' || value === null),
    };
  };
}
const { LINKS } = getConfig();

export const baseRoute = '/[accountUuid]';
export const pncBaseRoute = `${baseRoute}/insurance`;
export const ebBaseRoute = `${baseRoute}/employee-benefits`;
export const pageRoutes = {
  clientHome: appRoute<AccountRoute>(`${pncBaseRoute}/dashboard`),
  myServiceTeam: appRoute<AccountRoute>(`${baseRoute}/servicing-team`),
  settings: appRoute<AccountRoute>(`${baseRoute}/settings`),
  viewCertificates: appRoute<AccountRoute>(`${pncBaseRoute}/certificates`),
  viewCertificateDetails: appRoute<CertificateRoute>(`${pncBaseRoute}/certificates/[certificateId]`),
  viewPolicies: appRoute<AccountRoute>(`${pncBaseRoute}/policies`),
  viewPolicyDetails: appRoute<PolicyRoute>(`${pncBaseRoute}/policies/[policyUuid]`),
  viewClaims: appRoute<AccountRoute>(`${pncBaseRoute}/claims`),
  viewClaimDetails: appRoute<ClaimRoute>(`${pncBaseRoute}/claims/[claimUuid]`),
  viewAccountInsurableItemsDetails: appRoute<InsurableItemsQuery>(`${pncBaseRoute}/insurable-items`),
  viewManageTeamMembers: appRoute<AccountRoute>(`${baseRoute}/manage-team-members`),
  viewPnCFiles: appRoute<AccountRoute>(`${pncBaseRoute}/files`),
  viewEBFiles: appRoute<AccountRoute>(`${ebBaseRoute}/files`),
  viewBilling: appRoute<AccountRoute>(`${pncBaseRoute}/billing`),
  viewPlans: appRoute<AccountRoute>(`${ebBaseRoute}/plans`),
  viewVendorSearch: appRoute<AccountRoute>(`${LINKS.CLIENT_DASH_APP}/vendors/search`),
  viewResources: appRoute<AccountRoute>(`${ebBaseRoute}/resources`),
  viewTask: appRoute<TaskRoute>(`${pncBaseRoute}/tasks/[taskUuid]`),
};

export const newfrontAppRoutes = {
  uploadAttachmentForEntity: '/api-legacy/api/attachments/uploadAttachmentForEntity',
};

export const PROGRAM_TYPE_TO_ROUTE: Record<AccountProgramType, string> = {
  PROPERTY_AND_CASUALTY: pncBaseRoute,
  EMPLOYEE_BENEFITS: ebBaseRoute,
};
