import { ServiceType } from '@newfront-insurance/account-api';
import { NotFoundError } from '@newfront-insurance/data-layer-client';
import type { Route } from '@newfront-insurance/next-router-provider';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { AccountLayout } from '@/client/components/layouts/account-layout';
import { LoadingSpinner } from '@/client/components/query-loader/loading-spinner';
import { AccountDataProvider } from '@/client/providers/account-data';
import { RecentlyViewedServiceTypeProvider } from '@/client/providers/recent-service-type';
import { pageRoutes } from '@/client/routes';

export default function LandingPage(): JSX.Element {
  return (
    <AccountLayout>
      <Redirect>
        <LoadingSpinner loadingText="Loading..." />
      </Redirect>
    </AccountLayout>
  );
}

function Redirect(props: PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;
  const { serviceTypes, accountUuid, defaultServiceType } = useProvider(AccountDataProvider);
  const { recentlyViewedServiceType } = useProvider(RecentlyViewedServiceTypeProvider);
  const { pushRoute } = useRouter();

  useEffect(() => {
    const serviceTypeForRouting =
      recentlyViewedServiceType && serviceTypes.includes(recentlyViewedServiceType)
        ? recentlyViewedServiceType
        : defaultServiceType;

    const route = getServiceTypeRoute(accountUuid, serviceTypeForRouting);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    pushRoute(route);
  }, [accountUuid, defaultServiceType, pushRoute, recentlyViewedServiceType, serviceTypes]);

  return <>{children}</>;
}

/**
 * Get the root route for a servicing type.
 */
function getServiceTypeRoute(accountUuid: string, serviceType: ServiceType): Route {
  if (serviceType === ServiceType.PROPERTY_AND_CASUALTY) {
    return pageRoutes.clientHome({ accountUuid });
  }
  if (serviceType === ServiceType.EMPLOYEE_BENEFITS) {
    return pageRoutes.viewPlans({ accountUuid });
  }
  throw new NotFoundError('Service type not found.');
}
