/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ServiceType } from '@newfront-insurance/account-api';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';
import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import { AccountDataProvider } from './account-data';

import { ebBaseRoute, pncBaseRoute } from '@/client/routes';

export const RecentlyViewedServiceTypeProvider = createProvider(async () => {
  const { serviceTypes, defaultServiceType, accountUuid } = useProvider(AccountDataProvider);
  const { router } = useRouter();
  const { route } = router;

  const [recentlyViewedServiceType, setRecentlyViewedServiceType] = useLocalStorage(
    `recent-account-service-type-${accountUuid}`,
    defaultServiceType,
  );

  // This will update the recently viewed service type when the route changes
  useEffect(() => {
    if (serviceTypes.length > 1) {
      if (route.includes(pncBaseRoute)) {
        // P&C
        setRecentlyViewedServiceType(ServiceType.PROPERTY_AND_CASUALTY);
      } else if (route.includes(ebBaseRoute)) {
        // Employee benefits
        setRecentlyViewedServiceType(ServiceType.EMPLOYEE_BENEFITS);
      }
    }
  }, [recentlyViewedServiceType, route, serviceTypes, setRecentlyViewedServiceType]);

  return {
    recentlyViewedServiceType,
  };
});
